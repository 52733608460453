<template>
  <div
  class="flag-attivita"
  :class="{'expand': active}"
  @click="openModal();"
  >
  <template v-if="attivita.device==='manuale'"> <!--manuale-->

    <div class="evento-attivita"
    :class="classSport"
    >
    <div class="preview">
      <p v-if="attivita.titoloAllenamento" class="attivita-titolo" v-text="attivita.titoloAllenamento"></p>
      <p class="attivita-sport" v-text="attivita.gruppoSport"></p>
      <table class="flag-attivita-dati">
        <tr class="box distanza" v-if="attivita.distanza">
          <td class="attivita-dati-value">
            <i class="msp-icon-distance"></i>
            {{attivita.distanza}}
          </td>
        </tr>
        <!-- distanza -->

        <tr v-if="totTempo">
          <td class="attivita-dati-value"><i class="msp-icon-time"></i> {{totTempo}}
              </td>

"
            
        
        </tr>
      </table>
    </div>
    <div class="attivita-tooltip" v-if="attivita">
      <table class="flag-attivita-dati">
        <tr class="box distanza" v-if="attivita.distanza">
          <td class="attivita-dati-label">Distanza</td>
          <td class="attivita-dati-value">
            {{attivita.distanza}}
          </td>
        </tr>
        <!-- distanza -->

        <tr v-if="totTempo">
          <td class="attivita-dati-label">Tempo effettivo</td>
          <td class="attivita-dati-value">{{totTempo}}
          </td>
        </tr>

        <template v-if="attivita.gruppoSport === 'CICLISMO'">

          <tr v-if="velocitaMediaManuale">
            <td class="attivita-dati-label">
              Velocità media
            </td>
            <td class="attivita-dati-value">
              {{velocitaMediaManuale}} <span>km/h</span>
            </td>
          </tr>

        </template>


        <template v-if="attivita.gruppoSport !== 'CICLISMO'">
          <tr v-if="passoMedioManuale">
            <td class="attivita-dati-label">Passo medio</td>
            <td class="attivita-dati-value">
              {{passoMedioManuale}} <span>{{umPace(attivita.gruppoSport)}}</span>
            </td>
          </tr>

        </template><!-- passo -->




      </table>
    </div>
  </div>



</template>

<template v-else> <!--automatico-->
  <div
  class="evento-attivita"
  :class="classSport"
  >
  <div class="preview">
    <p v-if="attivita.titoloAllenamento" class="attivita-titolo" v-text="attivita.titoloAllenamento"></p>
    <p class="attivita-sport" v-text="attivita.sport"></p>
    <table class="flag-attivita-dati">
      <tr class="box distanza">
        <td class="attivita-dati-value">
          <i class="msp-icon-distance"></i>
          {{Math.round(attivita.distanza * 100)/100}} <span>km</span>
        </td>
      </tr>
      <!-- distanza -->

      <tr v-if="totTempo">
        <td class="attivita-dati-value"><i class="msp-icon-time"></i> {{totTempo}}
        </td>
      </tr>
      <tr v-if="countBests">
            <td :class="classBests" class="attivita-dati-value"><i class="icon-bests msp-icon-award" /> <span class="small">x</span> {{countBests}} </td>
        </tr>
    </table>
  </div>
  <div class="attivita-tooltip" v-if="attivita">

    <table class="flag-attivita-dati">
      <tr class="box distanza">
        <td class="attivita-dati-label">Distanza</td>
        <td class="attivita-dati-value">
          {{Math.round(attivita.distanza * 100)/100}} <span>km</span>
        </td>
      </tr>
      <!-- distanza -->

      <tr v-if="totTempo">
        <td class="attivita-dati-label">Tempo effettivo</td>
        <td class="attivita-dati-value">{{totTempo}}
        </td>
      </tr>

      <template v-if="attivita.gruppoSport === 'CICLISMO'">

        <tr v-if="avgSpeed">
          <td class="attivita-dati-label">Velocità media
          </td>
          <td class="attivita-dati-value">
            {{avgSpeed}} <span>km/h</span>
          </td>
        </tr>

      </template>


      <template v-if="attivita.gruppoSport !== 'CICLISMO'">
        <tr v-if="avgPace">
          <td class="attivita-dati-label">Passo medio</td>
          <td class="attivita-dati-value">
            {{avgPace}} <span>{{umPace(attivita.gruppoSport)}}</span>
          </td>
        </tr>
        <tr v-if="maxPace">
          <td class="attivita-dati-label">Passo massimo</td>
          <td class="attivita-dati-value">
            {{maxPace}} <span>{{umPace(attivita.gruppoSport)}}</span>
          </td>
        </tr>

      </template><!-- passo -->

      <tr v-if="attivita.trimp">
        <td class="attivita-dati-label">Trimp</td>
        <td class="attivita-dati-value">
          {{Math.round(attivita.trimp)}}
        </td>
      </tr>


      <tr v-if="attivita.calorie">
        <td class="attivita-dati-label">Calorie</td>
        <td class="attivita-dati-value">
          {{Math.round(attivita.calorie)}}
        </td>
      </tr>




    </table>
  </div>
</div>

</template>

</div>

</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import _funzioni from "@src/js/msp/funzioni.js"

export default {
  mixins: [
    allenamentoCalcoli,
  ],

  props: ['item'],

  computed: {
      attivita: function () {
          return this.item.data;
      },
      countBests: function () { 
          if (!(this.attivita && this.attivita.bests)) return false;
          return this.attivita.bests.length;
      },
      classBests: function () {
          if (!this.attivita) {
              return "";
          }
          if (!_funzioni.deepProp(this.attivita,'bests.length')) {
              return "";
          }
          let first = this.attivita.bests.filter(el=>el.posizione == 1);
          if (first.length) {
              return "gold";
          }
          return "";
      },
      velocitaMediaManuale: function () {
          if (!(this.attivita.distanzaValue && this.attivita.durata)) {
              return false;
          }
          let speed = this.attivita.distanzaValue / this.attivita.durata*3600;
          speed = Math.round(speed * 100) / 100;

          return speed;
      },
      passoMedioManuale: function () {
          if (!(this.attivita.distanza && this.attivita.durata)) {
              return false;
          }

          if (this.attivita.gruppoSport === "NUOTO") {
              return this.kmh2passo100mt(this.velocitaMediaManuale);
          } else {
              return this.kmh2passoKm(this.velocitaMediaManuale);
          }
      },
      classSport: function () {
          if (this.attivita.idSportCP) {
              return 'attivita-sport-'+this.attivita.idSportCP;
          }
          if (this.attivita.gruppoSport) {
              return 'attivita-sport-'+this.attivita.gruppoSport.toLowerCase();
          }
          return '';
      },
      totTempo: function () {
          return this.sec2hms(this.attivita.durata);
      },

      avgSpeed: function () {
          return Math.round(this.attivita.avgSpeed * 100) / 100;
      },

      maxSpeed: function () {
          return Math.round(this.attivita.maxSpeed * 100) / 100;
      },

      avgPace: function () {
          if (this.attivita.gruppoSport === "NUOTO") {
              return this.kmh2passo100mt(this.attivita.avgSpeed);
          } else {
              return this.kmh2passoKm(this.attivita.avgSpeed);
          }
      },

      maxPace: function () {
          if (this.attivita.gruppoSport === "NUOTO") {
              return this.kmh2passo100mt(this.attivita.maxSpeed);
          } else {
              return this.kmh2passoKm(this.attivita.maxSpeed);
          }
      },

      avgHr: function () {
          return Math.round(this.attivita.avgHR);
      },

      maxHr: function () {
          return Math.round(this.attivita.maxHR);
      },

      avgPower: function () {
          return Math.round(this.attivita.avgPower);
      },

      maxPower: function () {
          return Math.round(this.attivita.maxPower);
      },

      sogliaPower: function () {
          // TODO: calcolata da test: prende dal test WATT
          return  this.allenamentoSoglia;
      },

      normalizedPower: function () {
          return  Math.round(this.attivita.normPower);
      },

      intensitaMedia: function () {
          let normalizzata = this.normalizedPower;
          let soglia = this.sogliaPower;
          if (!soglia) {
              return "/";
          }
          return Math.round(normalizzata/soglia*100) / 100;
      },

      avgCadence: function () {
          return Math.round(this.attivita.avgCadence);
      },

      maxCadence: function () {
          return Math.round(this.attivita.maxCadence);
      },
  },

  data: function () {
    return {
      active: false
    }
  },
  watch: {

  },
  mounted: function() {

  },
  methods: {
    toggleActive: function ()
    {
      this.active = !this.active;
    },
    openModal: function () {
        this.$emit("open-modal", this.attivita);
    },
    umPace: function (gruppoSport)
    {
      return (gruppoSport==='NUOTO') ? 'min/100mt' : 'min/km';
    }
  }

}
</script>

<style lang="scss">
.flag-attivita {
  .attivita-sport {
    flex-basis: 15%;
    line-height: 1;
    padding: var(--ui-space);
    color: #fff;
    margin: 0;
    font-size: 0.7em;
  }

  .attivita-titolo {
    padding: var(--ui-space);
    color: #fff;
    margin: 0;
    font-weight: bold;
    flex-basis: 100%;
  }
  .preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    table {
      display: flex;
      justify-content: flex-start;
      td {
        background: rgba(255,255,255,0.6);
        padding: 0 var(--ui-space);
      }
    }
    td.gold  {
        background: gold;
    }
  }
  .attivita-tooltip {
    display: none;
    background: var(--color);
    color: #fff;
    padding: 3px;
    width: auto;
    min-width: 250px;
    border-radius: var(--ui-radius);
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    z-index: var(--z-tooltip);
  }
  &.expand .attivita-tooltip {
    display: block;
  }
  td {
    color: #000;
  }
  --color: var(--col-grigio-chiaro);
  .flag {
    width: 15px;
    height: 20px;
  }
  .flag-attivita-dati {
    td {
      padding: 0.1em;
      background: #fff;
      border-bottom: 1px solid var(--color);
    }
    td.attivita-dati-value {
      font-weight: bold;
    }
  }




  display: flex;
  justify-content: left;
  .flag-sport-1 {
    --color: var(--col-sport-1);
  }
  .flag-sport-2 {
    --color: var(--col-sport-2);
  }
  .flag-sport-3 {
    --color: var(--col-sport-3);
  }
  .flag-sport-4 {
    --color: var(--col-sport-4);
    fill: var(--color);
  }
  .flag {
    color: var(--color);
  }
  .flag-attivita {
    flex: 1;
  }
  .evento-attivita {
    width: 100%;
    background: var(--color);
    position: relative;
    margin-bottom: var(--ui-space);
    --color: #ccc;
    &.attivita-sport-podismo,
    &.attivita-sport-1 {
      --color: var(--col-sport-1);
    }
    &.attivita-sport-ciclismo,
    &.attivita-sport-2 {
      --color: var(--col-sport-2);
    }
    &.attivita-sport-nuoto,
    &.attivita-sport-3 {
      --color: var(--col-sport-3);
    }
    &.attivita-sport-4 {
      --color: var(--col-sport-4);
    }


  }
    .icon-bests {
        --ico-color: var(--col-grigio);
    }
    .small {
        font-size: 0.8em;
    }
}

</style>
