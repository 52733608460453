<template>
<div class="cp-calendar calendar-body">
  <msp-app-bar></msp-app-bar>
  <v-sheet
      tile
      height="54"
      color="grey lighten-3"
      class="calendar-nav d-flex"
  >
      <v-btn
          @click="openMiglioriPrestazioni"
          color="white"
          class="ma-2 btn-record">
          <span>
              <i class="v-icon msp-icon-award"></i>
          </span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          class="ma-2"
          @click="toggleCalendar()"
      >
          <span v-if="collapsed">
              <v-icon title="mostra calendario">mdi-calendar-search</v-icon>      
          </span>
          <span v-else>
              <v-icon title="nascondi calendario">mdi-calendar-minus</v-icon>      
          </span>
      </v-btn>
      <v-spacer></v-spacer>
      
     
      <v-btn
          @click="forceGoToTodayIfNeeded()"
          class="ma-2 cal-today">
          oggi
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          @click="refresh()"
          class="ma-2 cal-today">
          <v-icon>mdi-refresh</v-icon>
      </v-btn>
  </v-sheet>
    <v-sheet
    class="calendar-container"
    :class="classCalendar"
    >

    <vc-calendar
    ref="calendar"
    locale="it"
    :attributes="calendarEvents"
    @dayclick="goToDayFromCalendar"
    ></vc-calendar>

    </v-sheet>


<v-sheet
class="calendar-list mx-auto"
ref="calendar-list"
v-if="days"
>



<div class="calendar-cells">

<div
v-for="day in days"
class="calendar-day"
:key="'day-'+day.date"
:data-day="getFormattedDay(day.date)"
:class="classDay(day.date)" >
<div
:key="'cell-'+day.date"
class="calendar-table__cell"
:class="classGiorno(day.date)"
:style="'gridColumn: '+(dayNumber(day.date))" >
<div class="day-container"
@click="clickDay({date:day.date, event:$event})" >
<h4
class="day-number"
v-html="formatCalendarDay(day.date)" >
</h4>
<v-spacer></v-spacer>
<div class="day-badges">
</div>

<div class="day-impegno" v-if="isImpegno(day.date)" @click.stop="openImpegno({date:day.date})" >
<i :class="iconImpegno(day.date)" />
</div>
</div>
<div class="day-misurazioni">
<msp-misurazioni
        :misurazioni="getDayMisurazione(day.date)"
        :parametri="parametri"
        >
        </msp-misurazioni>
</div>
<div class="day-groups">
<div class="day-group"

v-for="(group, index) in groups"
:key="'group-'+index"
>
<div
class="day-group__item" :class="group"
v-for = "item in getDayGroupItems(day.date, group)"
:key = "'item-'+item.id"
>

</div>
</div>

</div>
<flag-attivita class="attivita"
v-for = "item in getDayAttivita(day.date)"
:key = "'attivita-'+item.id"
:item="item"
@open-modal="openModal(item)"

>
</flag-attivita>
<div class="day-content"
v-for = "item in getDayItems(day.date)"
:key="'content-'+item.id">
<template v-if="item.type==='allenamento'" >
<msp-allenamento-item
:allenamento="item.data"
@click="openModal(item)"
@open-attivita="openAttivita"
@open-chat-calendario="openChatCalendario"
></msp-allenamento-item>
</template>
<template v-if="item.type==='gara'" >
<msp-gara-item
:gara="item.data"
@click="openModal(item)"
@open-chat-calendario="openChatCalendario"
></msp-gara-item>
</template>
<template v-if="item.type==='test'" >
<msp-test-item
:test="item.data"
@click="openModal(item)"
></msp-test-item>
</template>

<template v-if="item.type==='risultato'" >
<msp-risultato-item
:risultato="item.data"
@click="openModal(item)"
></msp-risultato-item>

</template>
</div>

</div>
</div>
</div>


</v-sheet>
<v-sheet v-if="!atleta && !(days && days.length)">
<v-alert type="info">Nessun calendario selezionato</v-alert></v-sheet>
<v-snackbar
v-model="loading"
>
<v-btn
icon

:loading="loading"
></v-btn>

carico

</v-snackbar>
<v-dialog
v-model="showFabModal"
transition="dialog-bottom-transition"
>
</v-dialog>
 <v-speed-dial
 absolute
 v-show="canShowFab"
      v-model="fab"
      direction="top"
      :fab="false"
      :fling="false"
      :hover="false"
      :tabs="null"
      :top="false"
      :right="true"
      :bottom="true"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="blue darken-2"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>mdi-plus</v-icon>
          
        </v-btn>
      </template>
      <v-btn v-for="(fab,index) in filteredFab"
      :key="index"
        fab
        dark
        small
        :color="fab.color"
        @click="handleClickFab(...fab.click)" 
      >
      <span class="fab-label">{{fab.label}}</span> 
      <i v-if='fab.icon' :class="'msp-flat-icon ' + fab.icon"></i>
      <v-icon v-if='fab.md'>{{fab.md}}</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-overlay 
    :value="fab"
    :opacity="0.8"
    :z-index="0"
    ></v-overlay>
    
    <msp-app-footer></msp-app-footer>

<v-dialog
v-model="showModal"
scrollable
fullscreen
hide-overlay
transition="slide-x-transition"
:return-value="'detail'"
>
<router-view></router-view>
</v-dialog>
      </div>
</template>

<script>

import {fab as dataFab} from "@data/fab.js"
import moment from "moment";
import api from "@mixins/api.js";
import messaggi from "@mixins/messaggi.js";
import env from "@mixins/environment.js"
import MspAllenamentoItem from "@components/MspAllenamentoItem.vue";
import MspGaraItem from "@components/MspGaraItem.vue";
import MspMisurazioni from "@components/MspMisurazioni.vue";
import MspRisultatoItem from "@components/MspRisultatoItem.vue";
import MspAppBar from "@components/MspAppBar.vue";
import MspAppFooter from "@components/MspAppFooter.vue";
import MspTestItem from "@components/MspTestItem.vue";
import FlagAttivita from "@components/flagAttivita.vue";
import {  mapState, mapGetters } from 'vuex'
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import _cal from "@src/js/vue/mixins/calendario.js";
import version from "@mixins/version.js"

moment.locale("it");

const component = {
  
  mixins: [
    allenamentoCalcoli,
    env,
    version,
  ],

  components: {
    MspAllenamentoItem,
    MspGaraItem,
    MspMisurazioni,
    MspRisultatoItem,
    MspTestItem,
    MspAppBar,
    MspAppFooter,
    FlagAttivita,
  },

  beforeDestroy: function () {

      let list = document.querySelector('.calendar-list');
      if(list) {
          removeEventListener('scroll',this.onCalendarScroll);
      }
  },

  watch: {
      atletaId: function () {
          this.initCalendar();
      },
    dialog: function (val) {
      this.showModal = val;
      },

    $route: {
      immediate: true,
      handler: function(newVal) {
        this.showModal = newVal.meta && newVal.meta.showModal;
      }
    }

  },

  /**
   * TODO: gestire l'allenatore in modo globale.
   * In base alla condizione isCoach, carica delle parti o altre.
   */

  mounted: async function () {
      this.$forceNextTick(()=>{
          const container = document.querySelector('.calendar-list');
          container.addEventListener('scroll',this.onCalendarScroll);
      })
      this.current =  this.$route.query.day;
      this.intersectionObserverStart();


      this.loadParametri();
      this.isCoach = this.utente && !this.utente.is_atleta;
      if (!this.isCoach) {
          this.checkAlertAutovalutazione()
              .then(async (result)=>{
                  let interval;
                  if(result.interval) {
                      interval = result.interval;
                  } else {
                      interval = 0;
                  }
                  this.showAlertAutovalutazioneIfNeeded(result.last, interval);
              });
      }
      this.initCalendar();
  },

created () {
},

data: () => ({
    observer: null,
    pullController: null,
    parametri: [],
    tab: null,
    tabs: [
        { id: 1,
            text: 'atleti',
            icon: 'mdi-account',
        },
        { id: 2,
            text: 'feedback',
            icon: 'mdi-note-text',
        },
        { id: 3,
            text: 'zone',
            icon: 'mdi-heart',
        },
        { id: 4,
            text: 'esporta',
            icon: 'mdi-upload',
        },
    ],
    isCoach: false,
    fab: false,
    dataFab: dataFab,
    loading: false,
    showModal: false,
    showFabModal: false,
    canScroll: true,
    canLoad: true,
    from: _cal.date2ymd(),
    to: _cal.date2ymd(),
    today: _cal.date2ymd(),
    primoElementoVisibile: {
        element: null,
        y: 1000,
    },
    current: false,
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: '',
    collapsed: true,
    settimane: [],
    items: [],
    groups: [],
    days: [],
    weeks: [],
    autogroups: false,
    start: _cal.date2ymd(),
    end: '',
}),

computed: {
    ...mapState(
        {
            currentDay: (state)=>state.calendario.currentDay,
                dialog: (state)=>state.calendario.dialog,
                attivita: (state)=>state.calendario.attivita,
                peso: (state)=>state.calendario.peso,
                calendarEvents: (state)=>state.calendario.calendarEvents,
                limits: (state)=>state.calendario.limits,
        }
    ),
    ...mapGetters('calendario', [
        'impegni',
        'commenti',
        'misurazioni',
        'events',
    ]),
    ...mapGetters('atleti', [
        'atleti',
        'atleta',
        'atletaId',
    ]),
    ...mapGetters('utente', [
        'utente',
    ]),

    filteredFab: function () {
        let k = (this.isCoach) ? 'allenatore' : 'atleta';
        return this.dataFab[k];
    },

    classCalendar ()
    {
        return (this.collapsed) ? "collapsed" : "";
    },

    filteredTabs: function () {
        let tabs = [...this.tabs];
        return tabs;
    },

    canShowFab: function () {
        return this.atleta;
    },
},


methods: {
        intersectionObserverCallback: function (entries) {
            entries.forEach(entry => {
                void(entry);
            });

        },

        getListaDOM: function () {
            return this.$el.querySelector('.calendar-list');
        },
        intersectionObserverStart: function () {
            let options = {
                root: this.getListaDOM(),
                rootMargin: '0px',
                threshold: []
            }

            this.observer = new IntersectionObserver(this.intersectionObserverCallback, options);
        },
    initCalendar: async function () {
        if (!(this.atleta && this.atleta.id)) return;
        if (this.events && this.events.length) {
            if (this.currentDay) {
                await this.goToDay(this.currentDay);
            }
            return;
        }
        if (this.current) {
            await this.loadCalendarDate(this.current);
            await this.goToDay(this.current);
        } else {
            await this.goToToday();
        }
    },
    handleClickFab: function (fn,...args) {
        if (!args.length) {
            return this[fn]();
        } else {
            return this[fn](...args);
        }
    },

    loadParametri: async function () {
        const url = this.api_base_url +  '/api/v2/misurazione.php?parametri';
        const result = await api(url, "GET");
        if (!(result.success)) {
            this.parametri = [];
            return;
        }
        this.parametri = result.data;
    },

    setLastAlertAutovalutazione: function () {
        let url = this.api_base_url+"/api/v2/autovalutazione_atleta.php?last-alert";
        let data = {
            "value": _cal.date2ymd(),
        }
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });


    },
    setIntervalAutovalutazione: function () {
        let url = this.api_base_url+"/api/v2/autovalutazione_atleta.php?interval-alert";
        return new Promise((resolve,reject)=>{
            api(url,'post', null)
                .then((result)=>{
                    if (result.success) {
                        resolve(result.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });


    },

    showAlertAutovalutazioneIfNeeded: function (last, interval) {
        if (!interval) return;
        if (!last) {
            this.showAlertAutovalutazione();
        } else {
            let prossimo =_cal.addDays(last, interval);
            let oggi = _cal.date2ymd();
            let prossimoDate = _cal.utcDate(prossimo);
            let oggiDate = _cal.utcDate(oggi);
            let diff = (prossimoDate - oggiDate) / 3600/24/1000;
            if (diff<1) {
                this.showAlertAutovalutazione();
            }
        }
    },
    showAlertAutovalutazione: function () {
        this.addMisurazioneFromFab();
    },

    checkAlertAutovalutazione: function () {
        let url = this.api_base_url+"/api/v2/autovalutazione_atleta.php?last-alert";
        return new Promise((resolve,reject)=>{
            api(url,'get', null)
                .then((result)=>{
                    if (result.success) {
                        resolve(result.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });

    },

    findFirst: function (arr, container) {
        let top = container.getBoundingClientRect().top;
        for (let i = 0; i< arr.length ; i++) {
            let y= arr[i].getBoundingClientRect().top;
            if (y > top) {
               return  arr[i];
            }
        }
        return arr[arr.length-1];
    },

    openMiglioriPrestazioni: function () {
        this.$router.push("/record");
    },
    openImpegno: function ({date}) {
        const impegno = this.isImpegno(date);

        if (impegno) {
            this.openModal(impegno);
        }
    },
    clickDay: function ({date}) {
        this.openImpegno({date: date});
    },

    findImpegno: function (date) {
        let impegno = this.impegni.find(el=>{
            return el.start_ymd===date;
        });
        return impegno;
    },

    classGiorno: function (calendar) {
        let day = _cal.utcDate(calendar);
        let classes = [];
        classes.push('day-'+day.getDate());
        if (this.isToday(calendar)) {
            classes.push('oggi');
        }
        if (this.isFirstDay(calendar)) {
            classes.push('first');
        }
        return classes.join(" ");
    },

    updateCurrentDay: function () {
        let list = document.querySelector(".calendar-cells");
        let days =document.querySelectorAll('.calendar-day');
        let container = document.querySelector('.calendar-list');

        if (this.observer) {
        Array.from(days).forEach((el)=>{this.observer.observe(el)});
        }

        let cb = container.getBoundingClientRect();
        let lb = list.getBoundingClientRect();
        const diffTop = cb.top - lb.top;
        const diffBottom = Math.abs(cb.bottom - lb.bottom);
        let el =this.findFirst(days, container);
        if (el) {
            const currentDay = el.dataset.day;
            if (currentDay) {
                this.$store.dispatch("calendario/setCurrentDay", currentDay);
                this.focusDay(el.dataset.day);
            }
        }

        if (20 > diffTop) {
            this.loadPrev();
        }
        if (20 > diffBottom) {
            this.loadNext();
        }




    },

    onCalendarScroll: function () {
        this.MSP().debounce(this.updateCurrentDay, 100)();
    },



    loadCalendarEvents: async function (ev) {
        let limits = {
            start: _cal.date2ymd(new Date(ev.year, ev.month-1,1)),
            end: _cal.date2ymd(new Date(ev.year, ev.month,1)),
        }
        this.loading = true;
        await this.$store.dispatch("calendario/loadCalendarEvents", limits);

        this.loading = false;
    },



    openFabModalImpegno: function () {
        this.addImpegno()
            .then( async (result)=>{
                if (result) {
                    let event = result.data.event;
                    await this.$store.dispatch("calendario/addEvent", {event: event.data, type: event.type});
                    let item = this.events.find(el=>+el.id === +event.id);
                    if (item) {
                        this.openModal(item, "add");
                    }
                }
            });
    },

    openFabModalGara: function () {
        this.addGara()
            .then( async (result)=>{
                if (result) {
                    let event = result.data.event;
                    await this.$store.dispatch("calendario/addEvent", {event: event.data, type: event.type});
                    let item = this.events.find(el=>+el.id === +event.id);
                    if (item) {
                        this.openModal(item, "add");
                    }
                }
            });
    },

    openFabModalNota: function () {
        this.addNota()
            .then( async (result)=>{
                if (result) {
                    let event = result.data.event;
                    await this.$store.dispatch("calendario/addEvent", {event: event.data, type: event.type});
                    let item = this.events.find(el=>+el.id === +event.id);
                    if (item) {
                        this.openModal(item, "add");
                    }
                }
            });
    },

    addTestFromFab: async function () {
        this.$store.dispatch("calendario/setDialog",true);
        this.$router.push("/calendario/test/add");
    },


    addTest: function (data) {
        let url = this.api_base_url+"/api/v2/test.php";
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });
    },

    openFabModal: async function (type) {
        //this.showFabModal = true;
        let methods = {
            impegno: "openFabModalImpegno",
            gara: "openFabModalGara",
            nota: "openFabModalNota",
            test: "openFabModalTest",
        };
        this[methods[type]]();
    },

    openPeso: function (id) {
        let mode = (id) ? "edit" : "add";
        let item = {
            type: "peso",
            id: id,
        };
        this.openModal(item, mode);
    },

    openTest: function (id) {
        let item = {
            type: "test",
            id: id,
        };
        this.openModal(item, "edit");
    },

    openAutovalutazione: function (id) {
        let item = {
            type: "autovalutazione",
            id: id,
        };
        this.openModal(item, "edit");
    },

    openAttivita: function (attivita_id) {
        let item = {
            type: "attivita",
            id: attivita_id,
        };
        this.openModal(item);
    },
    openChatCalendario: async function (calendario_id) {
        const newChat = await messaggi.addChat('calendario', calendario_id);
        if (!newChat) return;
        const chatId = newChat.chat_id;
        this.$store.dispatch("calendario/setDialog",true);
        this.$router.push("/chats/"+chatId);
    },

    openModal: function (item, mode="view") {
        if (item.data && item.data.sport_id===this.MSP().ID_RIPOSO) {
            return;
        }
        this.$store.dispatch("calendario/setDialog",true);
        this.setDayFromString(item.start_ymd);
        let paramId;
        if (item.id) {
            paramId = "/"+item.id;
        } else {
            paramId = "";
        }
        if (item.type === "test") {
            const testId = item.data.test_id;
            const date = item.data.data;
            this.$router.push(`/calendario/test/add/${date}/${testId}`);
        } else {
            this.$router.push("/calendario/"+item.type+"/"+mode+paramId);
        }
    },


    setFirstDay: function (day) {
        this.firstDay = day;
    },
    onScrollEnd: function (args) {
        this.setFirstDay(args["first-day"]);
    },


    /* UTILITY*/



    /** Calcola la data partendo dalle coordinate (week, day) relative al giorno di partenza
     * @param {Object} calendar {day: index, week: i_w}
     * @return {Date}
     */
    getDay: function () {
        let inc = 0;
        let newDate = _cal.addDays(this.getStartDay(), inc);
        return newDate;
    },
    getMonth: function (calendar) {
        if (!calendar) {
            return "";
        }
        let newDate = _cal.utcDate(calendar);
        let month =  moment(newDate).format("MMMM YYYY");
        return month;
    },
    getData: function(calendar) {
        let newDate = this.getDay(calendar);
        let formattedDate =  moment(newDate).format("YYYY-MM-DD");

        return formattedDate;
    },


    updateCalendar: function () {
        let start = this.limits.start;
        let end = this.limits.end;
        this.days = this.makeDays(start, end);
        this.updateWeeks();
    },

    scrollToToday: function () {
        return this.scrollToDay(".oggi")
    },

    scrollToDay: function (selector) {
        //https://github.com/twickstrom/vue-force-next-tick
        return new Promise((resolve,reject)=>{
            this.$forceNextTick(
                ()=>{
                    let container = document.querySelector(".calendar-list");
                    if (!container)
                    {
                        resolve(false);
                        return false;
                    }
                    container.scrollTo(0,10);
                    let today = document.querySelector(selector);
                    if (!today)
                    {
                        resolve(false);
                        return false;
                    }
                    // let bounds = [container, today].map(el=>el.getBoundingClientRect());
                    let bounds = today.getBoundingClientRect();
                    let containerBounds = container.getBoundingClientRect();
                    container.scrollTo(0, bounds.top - containerBounds.top );
                    resolve(true);
                    return true;
                },
                (error)=>{
                    reject(error);
                }
            );


        });
    },

    refresh: function () {
        this.$store.dispatch("messaggio/countCommenti");
        this.forceGoToToday();
    },

    goToToday: async function() {
        let date =  _cal.date2ymd();
        await this.loadCalendarDate(date);
        this.goToDay(date);
    },
    forceGoToTodayIfNeeded: async function() {
        let date =  _cal.date2ymd();
        if (!_cal.inLimits(this.limits.start, this.limits.end, date)) {
            this.$store.dispatch("calendario/reset");
        }
        this.goToToday();
    },
    forceGoToToday: async function() {
        this.$store.dispatch("calendario/reset");
        this.goToToday();
    },
    goToDayFromCalendar: async function ({id}) {
        let date = id;
        this.$store.dispatch("calendario/reset");
        await this.loadCalendarDate(date);
        this.goToDay(date);
    },

    focusDay: async function (Ymd) {
        const calendar = this.$refs.calendar;
        if (!calendar) return;
        await calendar.focusDate(_cal.utcDate(Ymd));
    },

    loadCalendar: async function ({start, end}) {
        const limits = {
            start: start,
            end: end,
        }

        if (!this.atleta) return false;
        this.loading = true;
        await this.$store.dispatch("calendario/loadCalendar",limits);
        this.loading = false;
        return true;
    },

    loadCalendarDate: async function (date) {
        let limits = {
            start: date,
            end: _cal.addDays(date,7),
        }
        const loaded = await this.loadCalendar(limits);
        return loaded;
    },
    goToDay: async function (date) {
        this.setDayFromString(date);
        this.focusDay(date);
        this.updateCalendar();
        this.scrollToDay("[data-day='"+date+"']");
        this.$forceNextTick(()=>{
            const cellContainer = document.querySelector(".calendar-cells");
            if (!cellContainer) return;
            const cellBounds = cellContainer.getBoundingClientRect();
            const listContainer = document.querySelector(".calendar-list");
            const listBounds = listContainer.getBoundingClientRect();
            const diff = listBounds.height - cellBounds.height;
            if (diff > 0) {
                this.loadNext();
            }
        });
    },

    appendHistory: function (key, value) {
        let join = (location.search) ? "&" : "?";
        window.history.replaceState("scheda_allenamento", window.document.title, window.location.href+join+key+"="+value);
    },

    loadNext: async function () {
        if (!this.canLoad) { return; }
        this.loading = true;
        await this.$store.dispatch("calendario/loadNext");
        this.loading = false;
        this.updateCalendar();
    },

    loadPrev: async function () {
        if (!this.canLoad) { return; }
        const container = document.querySelector(".calendar-list");
        const cells = document.querySelector(".calendar-cells");
        if (!(cells && container)) return;
        const el = cells.firstChild;
        this.loading = true;

        await this.$store.dispatch("calendario/loadPrev");
        this.loading = false;

        this.updateCalendar();
        this.$forceNextTick(
            ()=>{
                if (!(el && container)) return;
                container.scrollTop += el.getBoundingClientRect().top - container.getBoundingClientRect().top
            }
        )

    },

    /** Alias di setDay */
    setDayFromString: function (ymd) {
        this.setDay(ymd);
    },

    setDay: function (day) {
        if (day) {
            this.day = day;
        } else {
            this.day = _cal.date2ymd();
        }
        if (this.MSP().url.getParam("day")) {
            window.history.replaceState("scheda_allenamento", window.document.title, window.location.href.replace(/day=[\d|-]+/gi,"day="+this.day));
        } else {
            this.appendHistory("day", this.day);
        }
    },



    makeDays: function (from, to) {
        return _cal.makeDays(from, to);
    },

    findCommentiByCalendarioId: function (calendarioId) {
        if (!this.commenti) return null;
        return this.commenti.find((el)=>{
            if (!el.chat) return false;
            return (el.chat.calendario_id == calendarioId)
        });
    },

    addCommenti: function (event) {
        const calendarioId = event.calendario_id;
        let commenti =this.findCommentiByCalendarioId(calendarioId);
        if (commenti) {
            event = {
                ...event,
                data: {
                    ...event.data,
                    commenti: commenti
                }
            }
        } else {
            event.data.commenti = null;
        }
        return event;
    },

    getDayItems: function (date) {
        let items = this.events.filter(el=>{
            let inDate = (el.data.data===date);
            return inDate;
        }).map(this.addCommenti).sort((a,b)=>(a.data.posizione > b.data.posizione)? 1 :-1);
        return items;

    },

    getDayAttivita: function (date) {
        let items = this.attivita.filter(el=>el.start_ymd===date);
        return items;
    },

    getDayPeso: function (date) {
        let items = this.peso.filter(el=>el.data===date);
        return items;
    },
    getDayMisurazione: function (date) {
        if (!(this.misurazioni && this.misurazioni.length)) return [];
        let items = this.misurazioni.filter(el=>el.data===date);
        return items;
    },

    getDayAutovalutazione: function (date) {
        let items = this.autovalutazione.filter(el=>el.data===date);
        return items;
    },

    getDayGroupItems: function (date,group) {
        return this.events.filter(el=>(el.start===date&&el.type===group)).sort((a,b)=>(a.data.posizione > b.data.posizione)?1:-1);
    },

    updateWeeks: function () {
        this.weeks = [];
        this.days.forEach(day=>{
            let monday = _cal.getMonday(new Date(day.date));
            let week;
            week = this.weeks.find(el=>el.monday === monday);
            if (!week) {
                week = {
                    monday: monday
                }
                this.weeks.push(week);
            }
            if (!(Object.prototype.hasOwnProperty.call(week,"days"))) {
                week.days = [];
            }
            if (!week.days.find(d=>d.date===day.date)) {
                week.days.push(day);
            }
            if (!this.weeks.find(w=>w.monday===week.monday)) {
                this.weeks.push(week);
            }
        });
        this.sortWeeks();
    },

    sortWeeks: function () {
        this.weeks = this.weeks.sort((a,b)=>(a.monday>b.monday)?1:-1);
        this.days = this.days.sort((a,b)=>(a.date>b.date)?1:-1);
    },

    dayNumber: function (calendar)
    {
        let day = new Date(calendar);
        return +day.getDay() || 7;
    },
    isFirstDay: function (calendar)
    {
        let day = new Date(calendar);
        return (+day.getDate()===1)
    },


    getFormattedDay: function(day) {
        return day;
    },
    iconImpegno: function (day_Ymd) {
        let impegno = this.findImpegno(day_Ymd);
        if (!impegno) return "";
        let icone = {
            "1": "msp-icon-impegno-1",
            "2": "msp-icon-impegno-2",
            "3": "msp-icon-impegno-3",
            "4": "msp-icon-impegno-4",
        };
        let id = impegno.data.impegno_tipo_id;
        let icona = icone[id];
        return icona;

    },
    classDay: function (day_Ymd) {
        let day = new Date(day_Ymd);
        let a_classes = [];
        a_classes.push("day-"+day.getDate());
        if (this.isToday(day_Ymd)) {
            a_classes.push("oggi");
        }
        if (this.isCurrentWeek(day_Ymd)) {
            a_classes.push("current-week");
        }
        if (this.isFirstDay(day_Ymd)) {
            a_classes.push("first");
        }
        let range = this.isImpegno(day_Ymd)
        if (range) {
            a_classes.push("day-range");
            a_classes.push("day-range-"+range.type);
            a_classes.push("day-range-"+range.type+"-"+range.data.impegno_tipo_descrizione);
            a_classes.push("day-range-"+range.type+"-"+range.data.impegno_tipo_id);
        }

        return a_classes.join(" ");
    },

    isCurrentWeek: function (day_Ymd) {
        let monday = _cal.getMonday(day_Ymd);
        const diff = this.today - monday;
        return ((diff > 0) && (diff < (7*24*60*60*1000)));
    },

    isToday: function (day_Ymd) {
        let isToday = (day_Ymd === this.today);
        return isToday;
    },

    isImpegno: function (day_Ymd) {
        let day = new Date(day_Ymd);
        let impegno = this.impegni && this.impegni.find(el=>{
            let d1 = el.start;
            let d2 = el.end || d1;
            let d_in =
                ((day >= d1) && (day <=d2));
            return d_in;
        });
        return impegno;
    },

    addImpegno: function () {
        const now = _cal.date2ymd();
        let data = {
            data: now,
            data_fine: now,
            impegno_tipo_id: 4,
        }
        let url = this.api_base_url+"/api/v2/calendario.php?impegno";
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });
    },

    addPesoFromFab: function () {
        this.openPeso(null);
    },

    addPeso: function (data) {
        let url = this.api_base_url+"/api/v2/peso.php";
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });
    },


    addMisurazioneFromFab: async function () {
        this.setLastAlertAutovalutazione();
        this.$store.dispatch("calendario/setDialog",true);
        this.$router.push("/calendario/misurazione/add");
    },


    addGara: function () {
        let data = {
            data: _cal.date2ymd(),
        }
        let url = this.api_base_url+"/api/v2/calendario.php?gara";
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });
    },

    addNota: function () {
        let data = {
            sport_id: 11,
            data: _cal.date2ymd(),
        }
        if (this.isCoach && this.atleta) {
            data.atleta_id = this.atleta.id;
        }
        let url = this.api_base_url+"/api/v2/calendario.php?allenamento";
        return new Promise((resolve,reject)=>{
            api(url,'post', data)
                .then((result)=>{
                    if (result.success) {
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                })
                .catch((error)=>{
                    reject(error);
                })
        });
    },

    getEventColor (event) {
        return event.color
    },
    rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
    },

    toggleCalendar ()
    {
        this.collapsed = !this.collapsed;
    },
}
}
export default component;
</script>

<style lang="scss">

.cp-calendar {
    .btn-record {
        color: var(--col-msp);
    }
    .v-speed-dial {
        position: absolute;
        z-index: 5 !important;
    }

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
    .day-impegno {
        i {
            font-family: "msp";
            font-size: 1em;
            position: relative;
        }
    }
    .day-badges {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        i{
            font-size: 1em;
        }

        --color: #fff;

        .day-badge {
            color: var(--color);
            line-height: 1;
            display: flex;
            flex-direction: row;
            gap: 3px;
        }
        .badge-autovalutazione {
            grid-column: 2;
        }
        .badge-peso {
            grid-column: 1;
            --color: #000;
        }
    }
    .calendar-day {


        &.day-range-impegno {
            .day-container {
                background-color: var(--col-grigio-scuro) !important;
                position: relative;
                justify-content: space-between;
            background-color: var(--col-grigio-scuro) !important;
            color: #fff;
            }
        }

    }


    .oggi .day-container{
        background-color: var(--col-ui-evidenza) !important;
    }

    .vc-container {
        .vc-bars {
            align-self: flex-start;
        }
        .vc-focusable {
            &[tabindex="0"]
            {
                border: 2px solid var(--col-msp);

            }
        }
        width: 100%;
        .vc-dot {
            --col: var(--col-grigio-medio);
            &:before {
                content: "";
                display: none;
            }
            background: var(--col);
            &.sport-1 {
                --col: var(--col-sport-1)
            }

            &.sport-2 {
                --col: var(--col-sport-2)
            }

            &.sport-3 {
                --col: var(--col-sport-3)
            }

            &.sport-4 {
                --col: var(--col-sport-4)
            }
            &.sport-5 {
                --col: var(--col-sport-5)
            }
            &.sport-10 {
                --col: var(--col-sport-10)
            }
            &.sport-11 {
                --col: var(--col-sport-11)
            }

        }
    }
    .calendar-list {
        flex: 1;
        flex-basis: 0;
        overflow-y: scroll; /* has to be scroll, not auto */
        // -webkit-overflow-scrolling: touch;
        width: 100%;
        padding-top: 10px;
    }

    .calendar-container {
        &.compact {
            .vc-container {


                --day-min-height: 10px;
                .vc-day {
                    max-height: 15px;
                    overflow: hidden;
                }
            }
        }

        .v-calendar-weekly {
            height: auto;
            &__day {
                display: flex;
                flex-wrap: wrap;
            }
            &__day-label {
                flex-basis: 100%;
            }

        }
        .v-event {
            width: 10px !important;
            height: 10px !important;
            margin: 2px;
            border-radius: 50%;
            overflow: hidden;
            &>div {
                display: none;
            }
        }
        --color: #000;
        transition: all 300ms ease-in-out;
        max-height: 100vh;
        &.collapsed {
            max-height: 0px;
            overflow: hidden;
            margin-bottom: 0px;
        }
    }

    .day-container {
        padding: 10px 20px;
        margin-bottom: 1px;
        background: var(--col-grigio-medio);
        color: #fff;
        display: flex;
        gap: 10px;
    }
}
.fab-label {
  position: absolute;
  right: 100%;
  transform:translateX(-10px);
}
</style>
