import _costanti from '../../msp/constants.js';
const sportConValori = _costanti.SPORT.filter(el=>+el.praticato).map(el=>el.id);

const sportHasValori = function (sportId) {
    return sportConValori.indexOf(+sportId) !== -1;
}

export default {
    methods: {
        allenamentoHasSlotNote: function (allenamento) {
            void(allenamento);
            return true;
        },
        allenamentoHasSlotPercentuale: function (allenamento) {
            return sportHasValori(allenamento.sport_id);
        },
        allenamentoHasSlotRpe: function (allenamento) {
            return sportHasValori(allenamento.sport_id);
        },
        allenamentoHasSlotValutazione: function (allenamento) {
            return allenamento.feedback_valutazione!==null
        },
        allenamentoHasFooter: function (allenamento) {
            return allenamento.sport_id != _costanti.ID_RIPOSO;
        },
    }
}
